import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import useRegionsDataQuery from './useRegionsDataQuery'
import CloseIcon from '@mui/icons-material/Close'
const nf = new Intl.NumberFormat('el-GR', { maximumSignificantDigits: 3 })

export default function ({ showList, setShowList, setSelObject }) {
  const { data } = useRegionsDataQuery()
  const [search, setSearch] = useState('')
  const [dasarxeio, setDasarxeio] = useState(0)
  const [category, setCategory] = useState(0)

  const regionsList =
    data?.filter(
      region =>
        region.id !== 0 &&
        (!search ||
          region.title.toUpperCase().indexOf(search.toUpperCase()) >= 0) &&
        (dasarxeio === 0 ||
          region.data.dasarxeio === ['', 'Λίμνης', 'Ιστιαίας'][dasarxeio]) &&
        (category === 0 ||
          region.data.eidos === ['', 'Δημόσιο', 'Μη Δημόσιο'][category])
    ) || []
  const getProgress = region => {
    const progr = region.sum.all[0]
    if (!progr) return 0
    const estim = region.sum.all[1]
    if (!estim) return 100
    if (progr > estim) return 100
    return (progr / estim) * 100
  }

  return (
    <Dialog
      open={showList}
      fullWidth
      maxWidth='sm'
      onClose={() => setShowList(false)}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            placeholder='Αναζήτηση'
            key={`searchField`}
            fullWidth
            variant='standard'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <IconButton small onClick={() => setShowList(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container mb={1} spacing={1}>
          <Grid item xs={6}>
            <FormControl size='small' fullWidth variant='standard'>
              <InputLabel>Δασαρχείο</InputLabel>
              <Select
                value={dasarxeio}
                onChange={e => setDasarxeio(e.target.value)}
                label='Δασαρχείο'
              >
                <MenuItem value={0}>Όλα</MenuItem>
                <MenuItem value={1}>Λίμνης</MenuItem>
                <MenuItem value={2}>Ιστιαίας</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl size='small' fullWidth variant='standard'>
              <InputLabel>Κατηγορία</InputLabel>
              <Select
                value={category}
                onChange={e => setCategory(e.target.value)}
                label='Κατηγορία'
              >
                <MenuItem value={0}>Όλα</MenuItem>
                <MenuItem value={1}>Δημόσια</MenuItem>
                <MenuItem value={2}>Μη Δημόσια</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer
          style={{
            background: 'white'
          }}
        >
          <Table size='small' stickyHeader>
            <TableBody>
              {regionsList.map((region, regionIdx) => (
                <TableRow
                  key={region.id}
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelObject([region, 'region'])
                    setShowList(false)
                  }}
                >
                  <TableCell width='90%' align='left'>
                    {region.title}
                    <Box fontSize={'0.8em'}>
                      Λήμμα: {nf.format(region.sum.all[1])} κ.μ , σε εξέλιξη:{' '}
                      {nf.format(region.sum.all[2])} κ.μ , ολοκλ.:{' '}
                      {nf.format(region.sum.all[0])} κ.μ
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}
