import React, { useEffect, useState } from 'react'
import useRegionsDataQuery from './useRegionsDataQuery'
import usePOIsQuery from './usePOIsQuery'
import { Box } from '@mui/material'
import { evoiaMap } from './evoiaMap'
import PoiIcon from './PoiIcon'
const rndm = new Array(1000).fill(0).map(r => Math.random())

export default function ({ selObject, setSelObject, showPOIs }) {
  const { data } = useRegionsDataQuery()
  const { data: poiData } = usePOIsQuery(11)

  const getColor = amount => {
    if(amount[0] + amount[1] === 0)return `rgb(200,200,200)`
    if(amount[0] === 0)return `rgb(150,150,150)`
    const val =
      amount[0] > amount[1] ? 1 : amount[1] === 0 ? 0 : amount[0] / amount[1]
    const r = 100 - Math.round(100 * val)
    const g = 100 + Math.round(100 * val)
    const b = 100 - Math.round(100 * val)
    return `rgb(${r},${g},${b})`
  }

  const checkRanges = (r1, r2) => {
    return [
      Math.min(r2[0], r1[0]),
      Math.max(r2[1], r1[1]),
      Math.min(r2[2], r1[2]),
      Math.max(r2[3], r1[3])
    ]
  }

  const getMapTransform = () => {
    if (!selObject) return {}
    const mapRanges = [
      23.1600404317273,
      23.725379435844,
      38.6744860902708,
      39.0396068333409
    ]
    if (selObject[1] === 'region') {
      const regionRanges = selObject[0].data.coords.reduce(
        (prevc, curc) =>
          checkRanges(
            prevc,
            curc.reduce(
              (prevs, curs) =>
                checkRanges([curs[0], curs[0], curs[1], curs[1]], prevs),
              [Infinity, -Infinity, Infinity, -Infinity]
            )
          ),
        [Infinity, -Infinity, Infinity, -Infinity]
      )
      const xPos =
        (((regionRanges[0] + regionRanges[1]) / 2 - mapRanges[0]) /
          (mapRanges[1] - mapRanges[0])) *
          -100 +
        50
      const yPos =
        (((regionRanges[2] + regionRanges[3]) / 2 - mapRanges[2]) /
          (mapRanges[3] - mapRanges[2])) *
          100 -
        50
      return {
        transform: `translate(${xPos}%, ${yPos}%)`
      }
    }
    if (selObject[1] === 'poi') {
      const xPos =
        ((selObject[0].lng - mapRanges[0]) / (mapRanges[1] - mapRanges[0])) *
          -100 +
        50
      const yPos =
        ((selObject[0].lat - mapRanges[2]) / (mapRanges[3] - mapRanges[2])) *
          100 -
        50
      return {
        transform: `translate(${xPos}%, ${yPos}%)`
      }
    }
  }

  const isSelectedPOI = id => {
    if (!selObject) return false
    if (selObject[1] !== 'poi') return false
    return id === selObject[0].id
  }

  const scaleSize = !selObject ? 1 : selObject[1] === 'region' ? 2.2 : 4;

  return (
    <Box
      sx={{
        width: '100vw',
        flexGrow: 1,
        margin: 'auto',
        marginTop: '80px',
        marginBottom: '20px',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: 'calc(max(700px,100vh) - 400px)',
          margin: 'auto',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            top: '0%',
            left: '0%',
            position: 'absolute',
            transition: 'transform 0.5s',
            transform: `scale(${scaleSize})`,
            transformOrigin: 'center'
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              top: '0%',
              left: '0%',
              position: 'absolute',
              transition: 'transform 0.5s',
              ...getMapTransform()
            }}
          >
            <Box
              sx={{
                width: '400%',
                height: '400%',
                top: '-200%',
                left: '-200%',
                position: 'absolute'
              }}
            >
              <svg
                viewBox='2204 -3980 224 144'
                style={{
                  width: '100%',
                  height: '100%',
                  transform: 'scaleY(1.3)',
                  transformOrigin: 'center',
                  position: 'absolute'
                }}
              >
                <polygon className='border' points={evoiaMap} />
                />
                {data
                  ?.filter(region => region.id !== 0)
                  .map((region, regionIdx) =>
                    region.data.coords.map((c, cidx) => (
                      <polygon
                        className={`region${
                          selObject &&
                          selObject[1] === 'region' &&
                          region.id === selObject[0].id
                            ? ' sel'
                            : ''
                        }`}
                        style={{ fill: getColor(region.sum.all) }}
                        key={`${region.id}-${cidx}`}
                        onClick={() => setSelObject([region, 'region'])}
                        points={c.map(p => `${p[0] * 100},${p[1] * -100}`).join(' ')}
                      />
                    ))
                  )}
                {showPOIs &&
                  poiData.map(poi => (
                    <PoiIcon
                      key={poi.id}
                      title={poi.title}
                      scaleSize={scaleSize}
                      {...{ poi, setSelObject }}
                    />
                  ))}
                {showPOIs &&
                  poiData
                    .filter(poi => isSelectedPOI(poi.id))
                    .map(poi => (
                      <PoiIcon
                        key={poi.id * -1}
                        title={poi.title}
                        scaleSize={scaleSize}
                        isSelectedPOI
                        {...{ poi, setSelObject }}
                      />
                    ))}
              </svg>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
