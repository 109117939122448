import React, { useState } from "react";
import "./InfoMap.scss";
import {
  Typography,
  Box,
  Button,
  ButtonGroup,
  Fade,
  Backdrop,
  CircularProgress
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";
import { getData } from "../lib/api";

import InfoMapDetails from "./InfoMapDetails";
import InfoMapRegions from "./InfoMapRegions";
import InfoMapAbout from "./InfoMapAbout";
import InfoMapList from "./InfoMapList";
import InfoMapLegend from "./InfoMapLegend";
import useRegionsDataQuery from "./useRegionsDataQuery";

export default function InfoMap({ isAdmin }){
  const [selObject, setSelObject] = useState(null);
  const [showAbout, setShowAbout] = useState(true);
  const [showPOIs, setShowPOIs] = useState(false);
  const [showList, setShowList] = useState(false);
  const { data } = useRegionsDataQuery();
  const upSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const objData =
    selObject && selObject[1] === "region"
      ? selObject[0]
      : data?.find(d => d.id === 0);
  const perc = objData?.sum.all[1]
    ? Math.min(100, Math.round((100 * objData.sum.all[0]) / objData.sum.all[1]))
    : 0;

  const saveData = async () => {
    const { res } = await getData(`/api/custom/apolipsis-data?save=1`);
    window.alert(res);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        minHeight: "700px",
        top: "0px",
        left: "0px",
        overflow: "hidden",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#277394"
      }}
    >
      <InfoMapRegions {...{ selObject, setSelObject, showPOIs }} />
      {!showAbout && !showList && objData && <InfoMapLegend />}
      {!showAbout && !showList && objData && (
        <InfoMapDetails {...{ selObject, setSelObject, objData }} />
      )}

      <Backdrop open={!showAbout && !objData}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <InfoMapAbout {...{ showAbout, setShowAbout }} />
      <Box
        position="fixed"
        top="0px"
        left="0px"
        right="0px"
        textAlign="center"
        color="white"
        padding="10px"
      >
        <Typography
          sx={{
            py: "10px",
            fontSize: showAbout ? "1.8em" : "1em",
            backgroundColor: "#223344aa"
          }}
        >
          ΠΡΟΟΔΟΣ ΑΠΟΛΗΨΗΣ{" "}
          <span style={{ whiteSpace: "nowrap" }}>ΒΟΡΕΙΑΣ ΕΥΒΟΙΑΣ</span>
        </Typography>
        <Box padding={1} textAlign="center">
          <Fade
            in={!showAbout}
            direction="down"
            style={{ transitionDelay: !showAbout ? "1s" : "0s" }}
            unmountOnExit
          >
            <Box>
              <ButtonGroup variant="contained" color="secondary" size="small">
                <Button
                  onClick={() => setShowAbout(true)}
                  startIcon={upSm && <InfoOutlinedIcon />}
                  sx={{ fontSize: '0.7em' }}
                >
                  ΠΛΗΡΟΦΟΡΙΕΣ
                </Button>
                <Button
                  onClick={() => setShowList(true)}
                  color={showList ? 'primary' : 'secondary'}
                  startIcon={upSm && <SearchIcon />}
                  sx={{ fontSize: '0.7em' }}
                >
                  ΑΝΑΖΗΤΗΣΗ
                </Button>
                <Button
                  onClick={() => setShowPOIs(!showPOIs)}
                  color={showPOIs ? 'primary' : 'secondary'}
                  startIcon={upSm && <PlaceIcon  />}
                  sx={{ fontSize: '0.7em' }}
                >
                  ΔΑΣΟΤΕΧΝΙΚΑ ΕΡΓΑ
                </Button>
                {isAdmin && (
                  <Button
                    onClick={() => saveData()}
                    color="secondary"
                    startIcon={upSm && <PublicIcon />}
                    sx={{ fontSize: "0.7em" }}
                  >
                    ΔΗΜΟΣΙΕΥΣΗ
                  </Button>
                )}
              </ButtonGroup>

              {Boolean(!showAbout && !showList && perc && (selObject || [])[1] !== 'poi') && (
                <Box position='relative'>
                  <Box
                    sx={{
                      position: 'absolute',
                      right: '5%',
                      top: '60px',
                      textAlign: 'right',
                      color: '#880000',
                      textShadow: '1px 1px white'
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        lineHeight: "0.8em"
                      }}
                    >
                      <Box display="inline" fontSize="5em" letterSpacing="-5px">
                        {perc}
                      </Box>
                      <Box display="inline" fontSize="2em" ml={1}>
                        %
                      </Box>
                    </Typography>
                    <Typography sx={{ fontSize: "0.8em" }}>
                      Ολοκλήρωση
                    </Typography>
                  </Box>
                </Box>
              )}

              {!selObject && (
                <Typography
                  sx={{
                    fontSize: "0.65em",
                    paddingTop: "5px",
                    textShadow: "1px 1px black"
                  }}
                >
                  Πατήστε σε μια περιοχή για περισσότερες λεπτομέρειες
                </Typography>
              )}
            </Box>
          </Fade>
        </Box>
      </Box>
      <InfoMapList {...{ showList, setShowList, setSelObject }} />
    </div>
  );
}
