import React from 'react'
import { Box, Typography, Button, Paper } from '@mui/material'

export default function () {
  return (
    <Typography
      sx={{
        color: 'white',
        textAlign: 'center',
        fontSize: '0.5em',
        zIndex: '1'
      }}
    >
      {[
        // { color: '#C8C8C8', label: 'Χωρίς πρόβλεψη' },
        { color: '#969696', label: 'Δεν έχει ξεκινήσει' },
        { color: '#646464', label: 'Σε εξέλιξη' },
        // { color: '#468246', label: 'Μερική ολοκλήρωση' },
        { color: '#00C800', label: 'Ολοκληρώθηκε' }
      ].map(({ color, label }, idx) => (
        <Box
          key={idx}
          display='inline-block'
          margin='4px 2px'
          padding='2px 5px 2px 22px'
          color='#444'
          backgroundColor='#ffffff'
        //   borderRadius='5px'
          position='relative'
        >
          <Box
            position='absolute'
            width='14px'
            top='2px'
            bottom='2px'
            left='2px'
            backgroundColor={color}
            // borderRadius='10px'
          />
          {label}
        </Box>
      ))}
    </Typography>
  )
}
