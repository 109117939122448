import React from 'react'
import { Box, Typography, Button, Paper } from '@mui/material'

export default function ({ showAbout, setShowAbout }) {
  const infoBoxStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#ffffff',
    opacity: showAbout ? 1 : 0,
    transition: 'all 1s',
    transform: showAbout ? 'translateY(0%)' : 'translateY(-100%)'
  }

  const bgrStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    background: "url('/static/img/forestbgr.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  const contentStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const imgStyle = {
    width: '75%',
    maxWidth: '300px'
  }

  return (
    <Box sx={infoBoxStyle}>
      <Box style={bgrStyle} />
      <Box style={contentStyle}>
        <Paper sx={{ p: '20px', m: '10px' }}>
          <img src='/static/img/eyboiameta.png' style={imgStyle} />
          <Typography
            sx={{ padding: '20px', maxWidth: '500px', margin: '20px auto' }}
          >
            Η συγκεκριμένη εφαρμογή έχει ως στόχο να δίνει στον επισκέπτη την
            πλήρη εικόνα για την πρόοδο της απόληψης των καμμένων δένδρων της
            Βόρειας Εύβοιας αλλά και να αποτελέσει ένα καινοτόμο εργαλείο για το
            κάθε Δασαρχείο στην εξέλιξη αυτής της προσπάθειας.
          </Typography>
          <Box sx={{ height: '20%' }}>
            <Button
              sx={{ fontSize: '1.4em' }}
              size='large'
              onClick={() => setShowAbout(false)}
              variant='contained'
            >
              ΕΝΑΡΞΗ
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
