export async function getData (url) {

  let headers = {
    'Content-Type': 'application/json'
  }
  return fetch(url, { headers, method: 'GET' })
    .then(res => (res.ok ? res : Promise.reject(res.statusText)))
    .then(res => (res.status === 204 ? {} : res.json()))
    .then(json => {
      if (json.error) return Promise.reject(json.error)
      return Promise.resolve(json)
    })
    .catch(msg => {
      console.log(msg)
      return Promise.reject(msg)
    })
}
