import { useQuery } from "react-query";
import { getData } from "../lib/api";

const getPosts = async (surveyId) => {
  if (!surveyId) return [];
  const refresh = Date.now();	
  return getData(`/files/apolipsis-poi.json?${refresh}`);
};

export default function usePOIsQuery(surveyId, regionId, key = "pois") {
  return useQuery([key, Number(surveyId)], () => getPosts(surveyId));
}
