import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  LinearProgress,
  IconButton,
  Grid,
  Tooltip as MuiTooltip  
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const nf = new Intl.NumberFormat("el-GR");

const hints = [
  "Ξυλεία που έχει ήδη αποληφθεί.",
  "Συνολική ξυλεία δασών με υλοτομία σε εξέλιξη.",
  "Μη υλοτομηθέντα για λόγους προστασίας ή απόκλιση από την αρχική εκτίμηση.",
  "Αρχική ενδεικτική εκτίμηση της απόληψης."
];

const refresh = Date.now();

const PoiDetails = ({ obj }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  useEffect(() => setImgLoaded(false), [obj]);
  return (
    <Grid container>
      <Grid item xs={12} md={imgLoaded ? 5 : 12}>
        <img
          alt=""
          onLoad={() => setImgLoaded(true)}
          src={`/files/itemPhotos/${obj.id}_med.jpg?${refresh}`}
          style={{
            width: "100%",
            height: "230px",
            objectFit: "contain",
            display: imgLoaded ? "block" : "none"
          }}
        />
      </Grid>
      <Grid item xs={12} md={imgLoaded ? 7 : 12}>
        <Typography padding={1}>{obj.data.descr}</Typography>
      </Grid>
    </Grid>
  );
};

export default function InfoMapDetails({ selObject, setSelObject, objData }) {
  const perc =
    objData.sum.all[0] > objData.sum.all[1]
      ? 100
      : objData.sum.all[1] === 0
      ? 0
      : Math.round((objData.sum.all[0] / objData.sum.all[1]) * 100);
  const progPerc =
    objData.sum.all[2] > objData.sum.all[1]
      ? 100
      : objData.sum.all[1] === 0
      ? 0
      : Math.round((objData.sum.all[2] / objData.sum.all[1]) * 100);

  return (
    <Paper
      style={{
        width: "95vw",
        maxWidth: "800px",
        height: "270px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        marginBottom: "10px",
        zIndex: "1",
        position: "relative"
      }}
    >
      {selObject && (
        <IconButton
          sx={{ position: "absolute", top: "5px", left: "5px" }}
          onClick={() => setSelObject(null)}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          px={4}
          pb={1}
          textAlign="center"
          fontSize="1.2em"
          flexGrow="1"
        >
          {selObject ? selObject[0].title : "Συνολική Πρόοδος"}
        </Typography>
      </Box>
      {(!selObject || selObject[1] !== "poi") && (
        <>
          <Box mx={4} sx={{ textAlign: "center", margin: "0px -20px" }}>
            {objData.sum.all[0] + objData.sum.all[1] === 0 ? (
              <Typography variant="body2" color="text.secondary">
                Δεν υπάρχει πρόβλεψη απόληψης για το συγκεκριμένο δάσος.
              </Typography>
            ) : (
              <Grid container>
                {[
                  { title: "Ολοκληρώθηκαν", val: objData.sum.all[0] - objData.sum.all[3] },
                  {
                    title: "Σε Εξέλιξη",
                    val: Math.max(objData.sum.all[2] - objData.sum.all[0], 0)
                  },
                  {
                    title: "Μη υλοτομηθέντα",
                    val: Math.max(objData.sum.all[3], 0)
                  },
                  { title: "Συνολικά", val: objData.sum.all[1] }
                ].map(({ title, val }, idx) => (
                  <Grid item xs={3} key={idx}>
                    <Box display="inline-block" textAlign="left" px={1}>
                      <Typography
                        sx={{
                          color: "#070",
                          fontSize: "1.1em",
                          fontWeight: "bold"
                        }}
                      >
                        {nf.format(Math.round(val))}
                        <span style={{ fontSize: "0.6em" }}>m³</span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: "-5px", fontSize: "0.6em" }}
                      >
                        {title}
                        <MuiTooltip title={hints[idx]} arrow placement="top">
                          <InfoOutlinedIcon
                            style={{
                              fontSize: "1.5em",
                              margin: "-4px 0px -4px 3px"
                            }}
                          />
                        </MuiTooltip>                        
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          <Box
            mx={4}
            sx={{ display: "flex", alignItems: "center", height: "30px" }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <LinearProgress
                variant="buffer"
                value={perc}
                valueBuffer={progPerc}
                sx={{
                  "& .MuiLinearProgress-dashedColorPrimary": {
                    background: "#eee",
                    animation: "none"
                  },
                  height: "18px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 4px #aaa"
                }}
              />
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
              <Typography
                variant='body2'
                color='text.secondary'
              >{`${perc}%`}</Typography>
            </Box> */}
          </Box>
        </>
      )}

      <Box flexGrow={1} position="relative" overflow="auto">
        {selObject && selObject[1] === "poi" ? (
          <PoiDetails key={selObject[0].id} obj={selObject[0]} />
        ) : (
          <Box
            position="absolute"
            left="0px"
            width="100%"
            height="100%"
            key={selObject ? selObject[0].id : 0}
            overflow="hidden"
            fontFamily="Roboto,Helvetica,Arial,sans-serif"
            fontSize="0.8em"
          >
            <Typography textAlign="center" fontSize="0.8em">
              {selObject ? "" : "Συνολική "}Πρόοδος Απόληψης ανά Μήνα (κ.μ.)
            </Typography>
            <Box
              position="absolute"
              top="5px"
              left="0px"
              width="100%"
              height="calc(100% - 20px)"
            >
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={400}
                  height={340}
                  data={objData.months}
                  margin={{
                    top: 15,
                    right: 30,
                    left: 0,
                    bottom: 0
                  }}
                  fontFamily={`"Roboto","Helvetica","Arial",sans-serif`}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="title" />
                  <YAxis />
                  <Tooltip
                    fontFamily={`"Roboto","Helvetica","Arial",sans-serif`}
                    formatter={(value, name, props) =>
                      `${nf.format(Math.round(value))} m³`
                    }
                  />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="Χαλέπιος Πεύκη"
                    stackId="1"
                    stroke="#ffc658"
                    fill="#ffc658"
                  />
                  <Area
                    type="monotone"
                    dataKey="Μαύρη Πεύκη"
                    stackId="1"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                  />
                  <Area
                    type="monotone"
                    dataKey="Ελάτη"
                    stackId="1"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                  <Area
                    type="monotone"
                    dataKey="Δρυς"
                    stackId="1"
                    stroke="#84aad8"
                    fill="#84aad8"
                  />
                  <Area
                    type="monotone"
                    dataKey="Αείφυλλα Πλατύφυλλα"
                    stackId="1"
                    stroke="#ca829d"
                    fill="#ca829d"
                  />
                  <Area
                    type="monotone"
                    dataKey="Λοιπά"
                    stackId="1"
                    stroke="#fa824d"
                    fill="#fa824d"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
