import { createTheme } from "@mui/material/styles";

export const theme =    createTheme({
        palette: {
            primary: {
                main: '#21423f',
            },
            secondary: {
                main: '#ffffff',
            }
        }
});