import React, {useState} from 'react'

export default function ({poi, isSelectedPOI, setSelObject, scaleSize}) {
  const [isHovered, setIsHovered] = useState(false);
  const iconSize = 3;
  const scale = 1 / scaleSize;

  const getIconDimensions = (poi) => {
    const size = isSelectedPOI ? iconSize * 1.5 : iconSize;

    return {
      width: size,
      height: size,
      x: poi.lng * 100 - size / 2,
      y: -poi.lat * 100 - size
    }; 
  }

  return (
    <svg
      style={{
        cursor: 'pointer',
        zIndex: isSelectedPOI ? 2 : 1
      }}
      onClick={() => setSelObject([poi, 'poi'])}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='-1 -1 67 83.53'
      {...getIconDimensions(poi)}
      preserveAspectRatio='none'
      className='forestWorkPoint'
    >
      <g id='Layer_2' data-name='Layer 2' style={{
            transition: "transform 0.5s",
            transformOrigin: "bottom center",
            transform: `scale(${scale})`        
      }}>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            stroke='#000'
            strokeWidth='2'
            strokeLocation='inside'
            fill={(isSelectedPOI || isHovered) ? '#800' : '#f80'}
            d='M32.46,82.53a12.63,12.63,0,0,1-2.24-1.75C22,70.63,13.51,60.73,5.84,50.2c-9.55-13.11-7-31.39,5-41.89,12.68-11,30.75-11.08,43.08-.08,12.5,11.16,14.82,28.68,4.88,42.15C51,61,42.38,71,34.1,81.29A8.5,8.5,0,0,1,32.46,82.53Z'
          />
          <polygon
            fill='white'
            points='8.03 27.33 21.85 27.3 21.85 30.28 9.88 30.28 8.03 27.33'
          />
          <path fill='white' d='M21.85,32.1v2.53H14.43l-1.74-2.57Z' />
          <polygon
            fill='white'
            points='18.63 36.45 21.85 36.45 21.85 38.74 19.64 38.74 18.63 36.45'
          />
          <polygon
            fill='white'
            points='56.11 27.33 42.29 27.3 42.29 30.28 54.25 30.28 56.11 27.33'
          />
          <path fill='white' d='M42.29,32.1v2.53h7.42l1.74-2.57Z' />
          <polygon
            fill='white'
            points='45.51 36.45 42.29 36.45 42.29 38.74 44.5 38.74 45.51 36.45'
          />
          <rect fill='white' x='23.37' y='22.22' width='1.57' height='21.46' />
          <rect fill='white' x='39.1' y='22.22' width='1.57' height='21.46' />
          <rect fill='white' x='26.11' y='32.24' width='11.63' height='2.42' />
          <rect fill='white' x='26.11' y='36.34' width='11.63' height='2.55' />
          <circle fill='white' cx='27.4' cy='42.13' r='1.59' />
          <circle fill='white' cx='31.75' cy='42.13' r='1.59' />
          <circle fill='white' cx='36.19' cy='42.13' r='1.59' />
        </g>
      </g>
    </svg>
  )
}