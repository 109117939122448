import InfoMap from "./infomap/InfoMap";
import { QueryClient, QueryClientProvider } from "react-query";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <InfoMap />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
