import { useQuery } from "react-query";
import { getData } from "../lib/api";

const getPosts = async () => {
  const refresh = Date.now();
  return getData(`/files/apolipsis-data.json?${refresh}`);
};

export default function useRegionsDataQuery() {
  return useQuery(["regionsdata"], () => getPosts());
}
